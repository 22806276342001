.header {
    height: 5rem;
    display: flex;
    padding: 0 10%;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(62,26,2);
  }
  
  .logo {
    font-size: 2rem;
    color: white;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav li {
    margin-left: 1.5rem;
    font-size: 1.25rem;
  }
  
  .nav a {
    text-decoration: none;
    color: #7a9d9d;
  }
  
  .nav a:hover,
  .nav a:active,
  .nav a.active {
    color: #e6fcfc;
  }
  