.product {
  max-width: 60rem;
  max-width: 90%;
  margin: 2rem auto;
  animation: produces-appear 1s ease-out forwards; 
  height: auto;
}

.product ul {
  list-style: none;
  margin: 0;
  padding: 0;
}



@keyframes produces-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}