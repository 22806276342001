.product {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #a44b4b;
}

.product h3 {
  margin: 0 0 0.25rem 0;
}

.description {
  font-style: italic;
}

.itemNavLink{
  text-decoration: none;
}

img {
  width: 100%;
  object-fit: cover;
  background-size: contain;
  background-size: cover;
}